import axios from 'axios';

/**
 * @description 설치다운로드
 * @author CHOI DAE GEON
 * @returns Promise
 */
const addDownloadCount = async () => {
  return await axios.post(`/api/installProgram/addDownloadCount`);
};

export { addDownloadCount };
